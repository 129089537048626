import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CalendarIcon } from "../assets/icons/calendar.svg";
import "moment/locale/hu";
import "../styles/exhibitions.scss";
import { errorImgSrc } from "../helpers";
import { FormattedMessage } from "react-intl";

function Exhibitions({ title, items }) {
  const [exhibitions, setExhibitions] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    moment.locale("hu");
    setExhibitions(items);
  }, [items]);

  const handleClick = (itemId) => {
    navigate(`/exhibitions/${itemId}`);
  };

  const formatDate = (startDate, endDate) => {
    let start;
    let end;
    if (startDate !== null && endDate !== null) {
      start = moment(startDate).format("YYYY. MMMM DD.");
      end = moment(endDate).format("MMMM DD.");
      return `${start} - ${end}`;
    } else if (startDate !== null && endDate === null) {
      return <FormattedMessage id="permanent_exhibition" />;
    } else {
      return "";
    }
  };

  if (!items?.length) {
    return <></>;
  }

  return (
    <section className="exhibitionsSection">
      <h1>{title}</h1>
      <div className="exhibitionContainer noScrollbar">
        {exhibitions &&
          exhibitions.map((item) => (
            <div
              className="exhibitionCard card"
              key={`exhibition_${item.id}`}
              onClick={() => handleClick(item.group_id)}
            >
              <img src={item.image || errorImgSrc} alt="background" />
              <div className="textContainer">
                <img
                  src={item.location_logo || errorImgSrc}
                  alt="brand"
                  className="brandImg"
                />
                <div className="infoContainer">
                  <h2>{item.name}</h2>
                  <p>
                    <CalendarIcon className="icon" />
                    {formatDate(item.start_date, item.end_date)}
                  </p>
                </div>
              </div>
              <div className="shadow"></div>
            </div>
          ))}
      </div>
    </section>
  );
}

export default Exhibitions;
