import React from "react";
import { useNavigate } from "react-router-dom";
import { errorImgSrc } from "../helpers";
import "../styles/exhibitions.scss";

function ExhibitionPlaces({ title, items, fontColor }) {
  let navigate = useNavigate();

  const handleClick = (itemId) => {
    navigate(`/places/${itemId}`);
  };

  if (!items?.length) {
    return <></>;
  }

  return (
    <section className="exhibitionPlaceSection">
      <div className="titleContainer">
        <h1 style={{ color: fontColor }}>{title}</h1>
      </div>
      <div className="exhibitionsContainer noScrollbar">
        {items &&
          items.map((item) => (
            <div
              className="exhibitionCard card"
              key={`exhibition_${item.id}`}
              onClick={() => handleClick(item.id)}
            >
              <img src={item.image || errorImgSrc} alt="image_alt" />
              <div className="textContainer">
                <h2 style={{ color: fontColor }}>
                  {item.exhibition_name || "Kiállítás"}
                </h2>
                <h3 style={{ color: fontColor }}>{item.name}</h3>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
}

export default ExhibitionPlaces;
