import Arts from "../components/Arts";
import CloseButton from "../partials/CloseButton";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MainService from "../services/mainService";
import ImageViewer from "react-simple-image-viewer";
import "../styles/exhibitions.scss";
import Gallery from "../components/gallery/Gallery";
import ExhibitionPlaces from "../components/ExhibitionPlaces";
import MapButton from "../partials/MapButton";
import Artists from "../components/Artists";
import { errorImgSrc } from "../helpers";
import MapModal from "../partials/MapModal";
import { FormattedMessage } from "react-intl";

function ExhibitionPage() {
  const [details, setDetails] = useState();
  const [readMore, setReadMore] = useState(false);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [colors, setColors] = useState({});

  let { id } = useParams();

  const handleReadMore = useCallback(() => {
    setReadMore((val) => !val);
  }, []);

  useEffect(() => {
    MainService.getExhibitionDetails(+id).then((resp) => {
      setDetails(resp.data);
      setColors(resp.data?.exhibition?.colors);
    });
  }, [id]);

  return (
    details && (
      <section className="exhibitionDetailsSection">
        <CloseButton />
        <div className="imageContainer">
          <img
            src={details?.exhibition?.image || errorImgSrc}
            alt="background"
            className="artImg"
          />
          <div className="titleContainer">
            <h1>{details?.exhibition?.name}</h1>
            {/* <h2>{details.subTitle}</h2> */}
          </div>
          <div className="shadow-full"></div>
        </div>
        <div
          className="detailsWrapper"
          style={{ backgroundColor: colors.content_background_color }}
        >
          <div className="detailsContainer">
            <h1 className="place" style={{ color: colors.content_text_color }}>
              {details?.location?.name}
            </h1>
            <span
              className="address"
              style={{ color: colors.content_text_color }}
            >
              <img src="../assets/icons/map.svg" alt="map" />
              {details?.location?.address}
            </span>
            {details?.exhibition?.description && (
              <>
                <div
                  className={`description ${readMore ? "showAllText" : ""}`}
                  dangerouslySetInnerHTML={{
                    __html: details.exhibition.description,
                  }}
                />
                <span
                  className="readMore"
                  onClick={() => handleReadMore()}
                  style={{ color: colors.content_text_color }}
                >
                  {readMore ? (
                    <FormattedMessage id="readless" />
                  ) : (
                    <FormattedMessage id="readmore" />
                  )}
                </span>
              </>
            )}
            <Arts
              title={<FormattedMessage id="exhibitions_artifacts" />}
              items={details?.artifacts}
              fontColor={colors.content_text_color}
            />
            <Artists
              items={details?.artists}
              fontColor={colors.content_text_color}
            />

            <ExhibitionPlaces
              items={details?.spaces}
              title={<FormattedMessage id="spaces" />}
              fontColor={colors.content_text_color}
            />
            <Gallery
              images={details?.exhibition?.gallery}
              fontColor={colors.content_text_color}
            />
          </div>
          <MapButton btnClick={() => setIsOpen(true)} />
        </div>
        {isViewerOpen && (
          <ImageViewer
            src={[details.imgSrc]}
            currentIndex={0}
            onClose={() => setIsViewerOpen(false)}
            disableScroll={true}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
            }}
            closeOnClickOutside={true}
            styles={{ zIndex: 4 }}
          />
        )}
        <MapModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          mapImg={details?.exhibition?.location_map}
          artifacts={details?.artifacts}
        />
      </section>
    )
  );
}

export default ExhibitionPage;
