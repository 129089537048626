import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import NavBar from "./components/NavBar";
import { LanguageProvider } from "./context/LanguagesContext";
import ScreenSaver from "./components/ScreenSaver";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LanguageProvider>
      <BrowserRouter>
        <ScreenSaver />
        <ScrollToTop>
          <App />
        </ScrollToTop>
        <NavBar />
      </BrowserRouter>
    </LanguageProvider>
  </React.StrictMode>
);
