import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import ImageViewer from "react-simple-image-viewer";
import { errorImgSrc } from "../../helpers";
import "./styles/gallery.scss";

function Gallery({ images, fontColor }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  if (!images?.length) {
    return <></>;
  }

  return (
    <section className="gallerySection" id="gallerySection">
      <h1 style={{ color: fontColor }}>
        <FormattedMessage id="gallery" />
      </h1>
      <div className="galleryWrapper noScrollbar">
        <div className="galleryContainer">
          {images &&
            images.map((item, idx) => (
              <img
                key={idx}
                className="galleryImage"
                src={item || errorImgSrc}
                onClick={() => openImageViewer(idx)}
                alt={`image_${idx}`}
              />
            ))}
        </div>
      </div>

      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={true}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
          styles={{ zIndex: 4 }}
        />
      )}
    </section>
  );
}

export default Gallery;
