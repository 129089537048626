import Exhibitions from "../components/Exhibitions";
import NavigationGallery from "../components/gallery/NavigationGallery";
import CloseButton from "../partials/CloseButton";
import Tags from "../partials/Tags";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MainService from "../services/mainService";
import "../styles/arts.scss";
import { errorImgSrc } from "../helpers";
import { FormattedMessage } from "react-intl";

function ArtistDetails() {
  const [details, setDetails] = useState();
  const [readMore, setReadMore] = useState(false);
  let { id } = useParams();
  // let navigate = useNavigate();

  const handleReadMore = useCallback(() => {
    setReadMore((val) => !val);
  }, []);

  useEffect(() => {
    MainService.getArtistDetails(+id).then((resp) => setDetails(resp.data));
  }, [id]);

  return (
    details && (
      <section className="artistSection">
        <CloseButton />
        <div className="heroImageContainer">
          <img
            src="../assets/artist_main.png"
            alt="keptar main"
            className="mainImg"
          />
        </div>
        <div className="profilImageContainer">
          <img
            src={details?.artist?.avatar || errorImgSrc}
            alt="profil_image"
            className="profileImage"
          />
          <h1>{details?.artist?.name}</h1>
          <span>({details?.artist?.birth_date})</span>
        </div>
        <div
          className={`description ${readMore ? "showAllText" : ""}`}
          dangerouslySetInnerHTML={{ __html: details?.artist?.content }}
        />
        <span className="readMore" onClick={() => handleReadMore()}>
          {readMore ? (
            <FormattedMessage id="readless" />
          ) : (
            <FormattedMessage id="readmore" />
          )}
        </span>
        <Tags items={details?.labels} />
        <Exhibitions
          title={<FormattedMessage id="available_exhibition" />}
          exhibitionType="elerheto"
        />
        <NavigationGallery images={details?.artifacts} />
      </section>
    )
  );
}

export default ArtistDetails;
