import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const navConfig = [
  {
    id: 1,
    url: "/",
    icon: "../assets/icons/home.svg",
    activeIcon: "../assets/icons/home_active.svg",
    altText: "home",
  },
  {
    id: 2,
    url: "/arts",
    icon: "../assets/icons/cube.svg",
    activeIcon: "../assets/icons/cube_active.svg",
    altText: "mütárgyak",
  },
  // {
  //   id: 3,
  //   url: "/search",
  //   icon: "../assets/icons/search.svg",
  //   activeIcon: "../assets/icons/search_active.svg",
  //   altText: "search",
  // },
  {
    id: 4,
    url: "/settings",
    icon: "../assets/icons/settings.svg",
    activeIcon: "../assets/icons/settings_active.svg",
    altText: "settings",
  },
];
function NavBar() {
  let navigate = useNavigate();
  const location = useLocation();

  const handleClick = (url) => {
    navigate(`${url}`);
  };

  function activeCheck(url) {
    return location.pathname === url ? "active" : "";
  }

  return (
    <div className="navBar">
      <div className="btnGroup">
        {navConfig.map((item) => (
          <button
            key={item.id}
            className={`navBtn ${activeCheck(item.url)}`}
            onClick={() => handleClick(item.url)}
          >
            <img src={item.icon} alt={item.altText} className="icon" />
            <img
              src={item.activeIcon}
              alt={item.altText}
              className="activeIcon"
            />
          </button>
        ))}
      </div>
    </div>
  );
}

export default NavBar;
