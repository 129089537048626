import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { errorImgSrc } from "../../helpers";
import "./styles/gallery.scss";

function NavigationGallery({ images }) {
  let navigate = useNavigate();

  return (
    images && (
      <section className="gallerySection" id="gallerySection">
        <h1>
          {images.length} <FormattedMessage id="ARTIFACT" />
        </h1>
        <div className="galleryWrapper noScrollbar">
          <div className="galleryContainer">
            {images &&
              images.map((item, index) => (
                <img
                  className="galleryImage"
                  src={item.imgSrc || errorImgSrc}
                  onClick={() => navigate(`/arts/${item.group_id}`)}
                  key={index}
                  alt={item.imgAlt}
                />
              ))}
          </div>
        </div>
      </section>
    )
  );
}

export default NavigationGallery;
