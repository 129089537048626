import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { errorImgSrc } from "../helpers";
import "../styles/arts.scss";

function Arts({ title, showAll, items, fontColor }) {
  const [arts, setArts] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    if (items) {
      setArts(items);
    }
  }, [items]);

  const handleClick = (itemId) => {
    navigate(`/arts/${itemId}`);
  };

  if (!items?.length) {
    return <></>;
  }

  return (
    <section className="artsSection">
      <div className="titleContainer">
        <h1 style={{ color: fontColor }}>{title}</h1>
        {showAll && (
          <span onClick={() => navigate("/arts/")} style={{ color: fontColor }}>
            <FormattedMessage id="all_items" />
          </span>
        )}
      </div>
      <div className="artsContainer noScrollbar">
        {arts &&
          arts.map((item) => (
            <div
              className="artCard card"
              key={`art_${item.id}`}
              onClick={() => handleClick(item.group_id)}
            >
              <img src={item.image || errorImgSrc} alt="image_alt" />
              <div className="textContainer" style={{ color: fontColor }}>
                <h2>{item.name || "No title"}</h2>
                <h3>{item.artist_name}</h3>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
}

export default Arts;
