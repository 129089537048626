import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import KeptarHomePage from "./pages/KeptarHomePage";
import Test from "./pages/ErrorPage";
import ArtsPage from "./pages/ArtsPage";
import ArtDetails from "./pages/ArtDetails";
import ArtistDetails from "./pages/ArtistDetails";
import ExhibitionPage from "./pages/ExhibitionPage";
import PlaceDetailsPage from "./pages/PlaceDetailsPage";
import SettingsPage from "./pages/SettingsPage";
import ErrorPage from "./pages/ErrorPage";
import MainService from "./services/mainService";
import { useLanguage } from "./context/LanguagesContext";

function App() {
  const { activeLanguage } = useLanguage();
  const [messages, setMessages] = useState({});

  useEffect(() => {
    MainService.getTranslations().then((resp) =>
      setMessages(resp.data[activeLanguage])
    );
  }, [activeLanguage]);

  return (
    <>
      <IntlProvider
        messages={messages}
        locale="hu"
        defaultLocale="hu"
        onError={() => {}}
      >
        <Routes>
          <Route path="/" element={<KeptarHomePage />} />
          <Route path="/places/:id" element={<PlaceDetailsPage />} />
          <Route path="/exhibitions/:id" element={<ExhibitionPage />} />
          <Route
            path="/featured/:id"
            element={<Test page="Kiemelt műtárgyak" />}
          />
          <Route path="/arts" element={<ArtsPage />} />
          <Route path="/arts/:id" element={<ArtDetails />} />
          <Route path="/artist/:id" element={<ArtistDetails />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
      </IntlProvider>
    </>
  );
}

export default App;
