import React from "react";
import { useIdleTimer } from "react-idle-timer";
import { useState } from "react";
import "../styles/screensaver.scss";
import { useNavigate } from "react-router-dom";

// const SCREENSAVER_TIMEOUT = 10000; // teszt (30mp)
const SCREENSAVER_TIMEOUT = 300000; // éles (5 perc)

function ScreenSaver() {
  const [screenSaverActive, setScreenSaverActive] = useState(false);
  let navigate = useNavigate();

  const onIdle = () => {
    setScreenSaverActive(true);
  };

  const onActive = () => {
    setScreenSaverActive(false);
    navigate("/");
  };

  // eslint-disable-next-line
  const idleTimer = useIdleTimer({
    onIdle,
    onActive,
    timeout: SCREENSAVER_TIMEOUT, // milliseconds
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    element: document.body,
    startOnMount: true,
  });

  return (
    <div
      className={`screenSaver ${screenSaverActive ? "scVisible" : "scHidden"}`}
    >
      <video autoPlay muted loop>
        <source src="../assets/red.mp4" type="video/mp4" />
      </video>
    </div>
  );
}

export default ScreenSaver;
