import { createContext, useContext } from "react";
import { useLocalStorage } from "./useLocalStorage";

const LanguageContext = createContext({});
export function useLanguage() {
  return useContext(LanguageContext);
}

export function LanguageProvider({ children }) {
  const [activeLanguage, setActiveLanguage] = useLocalStorage(
    "activeLang",
    "hu"
  );

  function changeActiveLanguage(value) {
    setActiveLanguage(value);
  }

  return (
    <LanguageContext.Provider
      value={{
        activeLanguage,
        changeActiveLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}
