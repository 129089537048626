import Arts from "../components/Arts";
import CloseButton from "../partials/CloseButton";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MainService from "../services/mainService";
import ImageViewer from "react-simple-image-viewer";
import "../styles/places.scss";
import Gallery from "../components/gallery/Gallery";
import ExhibitionPlaces from "../components/ExhibitionPlaces";
import MapButton from "../partials/MapButton";
import Featured from "../components/Featured";
import { errorImgSrc } from "../helpers";
import { FormattedMessage } from "react-intl";

function PlaceDetailsPage() {
  const [details, setDetails] = useState();
  const [readMore, setReadMore] = useState(false);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  let { id } = useParams();

  const handleReadMore = useCallback(() => {
    setReadMore((val) => !val);
  }, []);

  useEffect(() => {
    MainService.getPlaceDetails(+id).then((resp) => setDetails(resp.data));
  }, [id]);

  return (
    details && (
      <section className="placeDetailsSection">
        <CloseButton />
        <div className="imageContainer">
          <img
            src={details.imgSrc || errorImgSrc}
            alt="img"
            className="artImg"
          />
          <div className="titleContainer">
            <h1>{details?.space?.name}</h1>
            {/* <h2>{details.location}</h2> */}
          </div>
          <div className="shadow-full"></div>
        </div>
        <div className="detailsWrapper">
          <div className="detailsContainer">
            <h1 className="placeTitle">{details?.location?.name}</h1>
            <span className="address">
              <img src="../assets/icons/map.svg" alt="map" />
              {details?.location?.address}
            </span>
            <div
              className={`description ${readMore ? "showAllText" : ""}`}
              dangerouslySetInnerHTML={{ __html: details?.space?.content }}
            />
            <span className="readMore" onClick={() => handleReadMore()}>
              {readMore ? (
                <FormattedMessage id="readless" />
              ) : (
                <FormattedMessage id="readmore" />
              )}
            </span>
            <Featured items={details?.highlighted} />

            <Arts
              title={<FormattedMessage id="exhibitions_artifacts" />}
              items={details?.artifacts}
              showAll
            />

            <Gallery images={details?.space?.gallery} />
            <ExhibitionPlaces
              items={details?.other}
              title={<FormattedMessage id="spaces" />}
            />
          </div>
          <MapButton />
        </div>
        {isViewerOpen && (
          <ImageViewer
            src={[details?.space?.gallery]}
            currentIndex={0}
            onClose={() => setIsViewerOpen(false)}
            disableScroll={true}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
            }}
            closeOnClickOutside={true}
            styles={{ zIndex: 4 }}
          />
        )}
      </section>
    )
  );
}

export default PlaceDetailsPage;
