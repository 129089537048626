import React from "react";
import { useNavigate } from "react-router-dom";

function CloseButton({ returnUrl, noReturn = false, onClick = () => {} }) {
  let navigate = useNavigate();

  const handleClick = () => {
    if (noReturn) {
      onClick();
    } else {
      navigate(returnUrl || -1);
    }
  };

  return (
    <button className="closeBtn" onClick={() => handleClick()}>
      <img src="../assets/icons/cross.svg" alt="close_button" />
    </button>
  );
}

export default CloseButton;
