import React from "react";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useLanguage } from "../context/LanguagesContext";
import MainService from "../services/mainService";
import "../styles/settings.scss";

function SettingsPage() {
  const { activeLanguage, changeActiveLanguage } = useLanguage();
  const [languages, setLanguages] = useState();

  useEffect(() => {
    MainService.getLanguages().then((resp) => setLanguages(resp.data.data));
    // eslint-disable-next-line
  }, []);

  return (
    <section className="settingsSection">
      <h1>Beállítások</h1>
      <div className="titleContainer">
        <h2>{<FormattedMessage id="languageSelector" />}</h2>
        <p className="subtitle">{<FormattedMessage id="pleaseChoose" />}</p>
      </div>
      <div className="languagesContainer">
        {languages?.map((item) => (
          <div
            key={item.order}
            className={`languageCard ${
              activeLanguage === item.language_code && "active"
            }`}
            onClick={() => changeActiveLanguage(item.language_code)}
          >
            <img
              src={`../assets/flags/${item.language_code}.png`}
              alt="language"
            />
            <h2>{item.name}</h2>
            <img
              src="../assets/icons/check_mark.svg"
              alt="active_mark"
              className="circle"
            />
          </div>
        ))}
      </div>
    </section>
  );
}

export default SettingsPage;
