import React, { useCallback, useEffect, useState } from "react";
import Exhibitions from "../components/Exhibitions";
import MainService from "../services/mainService";
import Gallery from "../components/gallery/Gallery";
import "../styles/places.scss";
import Arts from "../components/Arts";
import AppDownload from "../components/AppDownload";
import { errorImgSrc } from "../helpers";
import { FormattedMessage } from "react-intl";

const mapExhibitions = (items) => {
  const periodic = items.filter(
    (i) => i.start_date !== null && i.end_date !== null
  );
  const permanent = items.filter(
    (i) => i.start_date !== null && i.end_date === null
  );
  return { permanent, periodic };
};

function KeptarHomePage() {
  const [readMore, setReadMore] = useState(false);
  const [data, setData] = useState([]);
  const [exhibitions, setExhibitions] = useState([]);
  const [artifacts, setArtifacts] = useState();

  useEffect(() => {
    MainService.getDefaultPlace().then((resp) => {
      setData(resp.data);
      setExhibitions(mapExhibitions(resp.data.exhibitions));
    });
    MainService.getArts().then((r) => setArtifacts(r.data));
  }, []);

  const handleReadMore = useCallback(() => {
    setReadMore((val) => !val);
  }, []);

  return (
    <>
      <section className="mainSection">
        <div className="heroImageContainer">
          <img
            src="../assets/keptar_main.png"
            alt="keptar main"
            className="mainImg"
          />
          <img
            src={data?.location?.logo || errorImgSrc}
            alt="logo"
            className="logo"
          />
        </div>
        <div className="textContainer center padding">
          <h2>{data?.location?.name}</h2>
          <p>{data?.location?.address}</p>
        </div>
        <div
          className={`description ${readMore ? "showAllText" : ""}`}
          dangerouslySetInnerHTML={{ __html: data?.location?.description }}
        />
        <span className="readMore" onClick={() => handleReadMore()}>
          {readMore ? (
            <FormattedMessage id="readless" />
          ) : (
            <FormattedMessage id="readmore" />
          )}
        </span>
      </section>
      <div className="exhibitionsAndGallery">
        <Exhibitions
          title={<FormattedMessage id="periodic_exhibitions" />}
          items={exhibitions?.periodic}
        />
        <Exhibitions
          title={<FormattedMessage id="permanent_exhibitions" />}
          items={exhibitions?.permanent}
        />
        <Gallery images={data?.location?.gallery} />
        <Arts
          title={<FormattedMessage id="exhibitions_artifacts" />}
          items={artifacts?.highlighted}
          showAll
        />
      </div>
      <AppDownload />
    </>
  );
}

export default KeptarHomePage;
