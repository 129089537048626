import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Modal from "react-modal";
import { errorImgSrc } from "../helpers";
import "../styles/mapmodal.scss";
import ArtItem from "./ArtItem";
import CloseButton from "./CloseButton";
import MapItem from "./MapItem";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function MapModal({
  isOpen,
  onClose,
  mapImg,
  onlyOneArt,
  artPosition,
  artifacts,
}) {
  const [activeArt, setActiveArt] = useState();
  return (
    <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false}>
      <div className="mapModalHeader">
        <CloseButton noReturn onClick={() => onClose()} />
        <h1>{<FormattedMessage id="map" />}</h1>
      </div>
      <>
        {onlyOneArt ? (
          <div className="mapModalContent">
            <img src={mapImg || errorImgSrc} alt="map" />
            <MapItem onlyOneItem position={artPosition} />
          </div>
        ) : (
          <>
            <div className="mapModalContent">
              <img src={mapImg || errorImgSrc} alt="map" />
              {artifacts?.map((art) => (
                <MapItem
                  item={art}
                  position={art?.position}
                  handleOnClick={(id) => setActiveArt(id)}
                  activeArt={activeArt}
                />
              ))}
            </div>
            <div key={activeArt} className="artifactList noScrollbar">
              {artifacts?.map((art) => (
                <ArtItem item={art} activeArt={activeArt} />
              ))}
            </div>
          </>
        )}
      </>
    </Modal>
  );
}

export default MapModal;
