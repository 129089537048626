import React from "react";
import "./styles/map.scss";

function MapButton({ btnClick }) {
  return (
    <div className="mapBtnContainer" onClick={() => btnClick()}>
      <button className="mapBtn">
        <img src="../assets/icons/map.svg" alt="map" />
      </button>
    </div>
  );
}

export default MapButton;
