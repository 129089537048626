import React, { useEffect } from "react";
import Featured from "../components/Featured";
import CloseButton from "../partials/CloseButton";
import "../styles/arts.scss";
import MainService from "../services/mainService";
import { useState } from "react";
import Arts from "../components/Arts";
import { FormattedMessage } from "react-intl";

function ArtsPage() {
  const [data, setData] = useState([]);
  const [allArts, setAllArts] = useState([]);

  useEffect(() => {
    MainService.getAllArts().then((resp) => {
      setAllArts(resp.data.data);
    });
    MainService.getArts().then((resp) => {
      setData(resp.data);
    });
  }, []);

  return (
    <>
      <CloseButton />
      <Featured items={data?.highlighted} />
      <Arts
        title={<FormattedMessage id="latest_added" />}
        items={data?.latest}
      />
      <Arts title={<FormattedMessage id="all_artifact" />} items={allArts} />
    </>
  );
}

export default ArtsPage;
