import axios from "axios";

const BASE_URL = "https://keptar.chrome.hu";
const MAIN_URL = `${BASE_URL}/api/client/terminal`;

function axiosConfig() {
  const activeLanguage = localStorage.getItem("activeLang");
  let config = {
    headers: {
      "X-LANG": activeLanguage,
    },
  };
  return config;
}

function getPlaces() {
  return axios.get(`${MAIN_URL}/homescreen`, axiosConfig());
}
function getPlaceById(id) {
  return axios.get(`${MAIN_URL}/homescreen/${id}`, axiosConfig());
}

function getDefaultPlace() {
  return axios.get(`${MAIN_URL}/homescreen`, axiosConfig());
}
function getPlaceDetails(groupId) {
  return axios.get(`${MAIN_URL}/spaces/${groupId}`, axiosConfig());
}

function getExhibitionDetails(groupId) {
  return axios.get(`${MAIN_URL}/exhibitions/${groupId}`, axiosConfig());
}

function getArtistDetails(groupId) {
  return axios.get(`${MAIN_URL}/artists/${groupId}`, axiosConfig());
}

function getArts() {
  return axios.get(`${MAIN_URL}/artifacts`, axiosConfig());
}
function getAllArts() {
  return axios.get(`${MAIN_URL}/artifacts/all`, axiosConfig());
}

function getArtDeatils(groupId) {
  return axios.get(`${MAIN_URL}/artifacts/${groupId}`, axiosConfig());
}

function getLanguages() {
  return axios.get(`${MAIN_URL}/languages`);
}

function getTranslations() {
  return axios.get(`${MAIN_URL}/translations`);
}

const MainService = {
  getPlaces,
  getPlaceById,
  getDefaultPlace,
  getPlaceDetails,
  getExhibitionDetails,
  getArts,
  getAllArts,
  getArtDeatils,
  getArtistDetails,
  getLanguages,
  getTranslations,
};
export default MainService;
