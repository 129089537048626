import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { errorImgSrc } from "../helpers";
import "../styles/artists.scss";

function Artists({ items, fontColor }) {
  const [artists, setArtists] = useState([]);

  useEffect(() => {
    if (items) {
      setArtists(items);
    }
  }, [items]);

  if (!items?.length) {
    return <></>;
  }

  return (
    <section className="artistsSection">
      <h1 style={{ color: fontColor }}>
        <FormattedMessage id="discover_artists" />
      </h1>
      <div className="artistsContainer noScrollbar">
        {artists &&
          artists.map((item) => (
            <div className="artistCard card" key={item.id}>
              <img src={item.avatar || errorImgSrc} alt="image_alt" />
              <div className="textContainer">
                <h2 style={{ color: fontColor }}>{item.name}</h2>
                {/* <h3>{item.job}</h3> */}
              </div>
            </div>
          ))}
      </div>
    </section>
  );
}

export default Artists;
