import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { errorImgSrc } from "../helpers";
import "../styles/featured.scss";

function Featured({ title, items }) {
  const [featuredItems, setFeaturedItems] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    if (items) {
      setFeaturedItems(items);
    }
  }, [items]);

  const handleClick = (itemId) => {
    navigate(`/arts/${itemId}`);
  };

  if (!items?.length) {
    return <></>;
  }

  return (
    <section className="featuredSection">
      <h1>{title || <FormattedMessage id="highlightedArtifact" />}</h1>
      <div className="featuredContainer noScrollbar">
        {featuredItems &&
          featuredItems.map((item) => (
            <div
              className="featuredCard card"
              key={item.id}
              onClick={() => handleClick(item.group_id)}
            >
              <img
                src={item.image || errorImgSrc}
                alt="background"
                className="artImage"
              />
              <div className="textContainer">
                <img
                  src={item.artist_avatar || errorImgSrc}
                  alt="creator_avatar"
                  className="avatar"
                />
                <div>
                  <h2>{item.name || "Cím nélkül"}</h2>
                  <h3>{item.artist_name}</h3>
                </div>
              </div>
              <div className="shadow"></div>
            </div>
          ))}
      </div>
    </section>
  );
}

export default Featured;
