import React from "react";

function MapItem({
  item,
  position,
  onlyOneItem,
  activeArt,
  handleOnClick = () => {},
}) {
  if ((!item && !onlyOneItem) || !position) {
    return <></>;
  }

  return (
    <>
      {onlyOneItem ? (
        <div
          key="art_item"
          className={"mapItem"}
          style={{ top: position.y, left: position.x }}
        >
          <div className="internal"></div>
        </div>
      ) : (
        <div
          key={item.id}
          className={`mapItem ${activeArt === item.id ? "active" : ""}`}
          style={{ top: position.y, left: position.x }}
          onClick={() => handleOnClick(item.id)}
        >
          <div className="internal"></div>
        </div>
      )}
    </>
  );
}

export default MapItem;
