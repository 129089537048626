import React from "react";
import "../styles/tags.scss";

function Tags({ items }) {
  return (
    items && (
      <div className="tags">
        {items.map((tag) => (
          <span key={tag.id}>{tag.name}</span>
        ))}
      </div>
    )
  );
}

export default Tags;
