import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import "../styles/mapmodal.scss";

function ArtItem({ item, activeArt }) {
  let navigate = useNavigate();

  if (!item) {
    return <></>;
  }

  return (
    <div
      key={item.id}
      className={`artItem ${activeArt === item.id ? "active" : ""}`}
    >
      <img src={item.image} alt="img" />
      <div className="textContainer">
        <h1>{item.name}</h1>
        <h2>{item.artist_name}</h2>
      </div>
      <p onClick={() => navigate(`/arts/${item.group_id}`)}>
        <FormattedMessage id="reszletek" />
      </p>
    </div>
  );
}

export default ArtItem;
