import React from "react";
import { FormattedMessage } from "react-intl";
import "../styles/appdownload.scss";

function AppDownload() {
  return (
    <section className="appDownloadSection">
      <div className="textContainer">
        <h1>
          <FormattedMessage id="downloadHeader" />
        </h1>
        <p>
          <FormattedMessage id="downloadText" />
        </p>
        <div className="storeButtons">
          <img src="../assets/Google.svg" alt="GoogleStore" />
          <img src="../assets/Apple.svg" alt="AppleStore" />
        </div>
      </div>
      <div className="imgAndQR">
        <div className="background"></div>
        <img src="../assets/qr.png" alt="app_qr_code" className="qr" />
      </div>
    </section>
  );
}

export default AppDownload;
