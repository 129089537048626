import React from "react";
import { useNavigate } from "react-router-dom";

function ErrorPage() {
  let navigate = useNavigate();

  return (
    <section className="errorSection">
      <h1>Sajnáljuk, az oldal nem elérhető!</h1>
      <img src="../assets/error.png" alt="error_image" />
      <div className="btn" onClick={() => navigate("/")}>
        Vissza a főoldalra
      </div>
    </section>
  );
}

export default ErrorPage;
