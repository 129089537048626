import Arts from "../components/Arts";
import Exhibitions from "../components/Exhibitions";
import CloseButton from "../partials/CloseButton";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainService from "../services/mainService";
import ImageViewer from "react-simple-image-viewer";
import "../styles/arts.scss";
import Tags from "../partials/Tags";
import Gallery from "../components/gallery/Gallery";
import MapButton from "../partials/MapButton";
import { errorImgSrc } from "../helpers";
import { FormattedMessage } from "react-intl";
import MapModal from "../partials/MapModal";

function ArtDetails() {
  const [details, setDetails] = useState();
  const [readMore, setReadMore] = useState(false);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  let { id } = useParams();
  let navigate = useNavigate();

  const handleReadMore = useCallback(() => {
    setReadMore((val) => !val);
  }, []);

  useEffect(() => {
    MainService.getArtDeatils(+id).then((resp) => setDetails(resp.data));
  }, [id]);

  const handleFullScreenClick = useCallback(() => {
    setIsViewerOpen(true);
  }, []);

  return (
    details && (
      <section className="artDetailsSection">
        <CloseButton />
        <div className="imageContainer">
          <img
            src={details?.artifact?.gallery[0] || errorImgSrc}
            alt="img"
            className="artImg"
          />
          <button
            className="fullScreenBtn"
            onClick={() => handleFullScreenClick()}
          >
            <img src="../assets/icons/fullscreen.svg" alt="fullscreen_button" />
          </button>
        </div>
        <div className="detailsWrapper">
          <div className="detailsContainer">
            <h1>{details?.artifact?.name}</h1>
            {/* <span className="size">{`${details?.size?.width} x ${details?.size?.height} ${details?.size?.unit}`}</span> */}
            <div
              className={`description ${readMore ? "showAllText" : ""}`}
              dangerouslySetInnerHTML={{ __html: details?.artifact?.content }}
            />
            <span className="readMore" onClick={() => handleReadMore()}>
              {readMore ? (
                <FormattedMessage id="readless" />
              ) : (
                <FormattedMessage id="readmore" />
              )}
            </span>
            <Tags items={details?.labels} />
            <div
              className="artistContainer"
              onClick={() => navigate(`/artist/${details?.artist?.group_id}`)}
            >
              <img src={details?.artist?.avatar} alt="artist" />
              <h2>{details?.artist?.name}</h2>
              <h3>{details?.artist?.job}</h3>
            </div>
          </div>
          <Gallery images={details?.artifact?.gallery} />
          <Exhibitions
            title="Elérhető kiállítások"
            exhibitionType="elerheto"
            items={[] /* details?.exhibition */}
          />
          <Arts title="Hasonló műtárgyak" />
          <MapButton btnClick={() => setIsOpen(true)} />
        </div>
        {isViewerOpen && (
          <ImageViewer
            src={[details?.artifact?.gallery[0]]}
            currentIndex={0}
            onClose={() => setIsViewerOpen(false)}
            disableScroll={true}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
            }}
            closeOnClickOutside={true}
            styles={{ zIndex: 4 }}
          />
        )}
        <MapModal
          isOpen={isOpen}
          onlyOneArt
          artPosition={details?.artifact?.position}
          onClose={() => setIsOpen(false)}
          mapImg={details?.artifact?.location_map}
        />
      </section>
    )
  );
}

export default ArtDetails;
